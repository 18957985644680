<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="order-setting-wrapper">
          <div class="accordion" id="orderAccordion">

            <div class="card">
              <div class="card-header" id="headingOrderOrderNumberPrefix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOrderOrderNumberPrefix" aria-expanded="false" aria-controls="collapseOrderOrderNumberPrefix">

                  <span class="text-capitalize order-setting-title">Order Order Number Prefix</span>

                </button>
              </div>
              <div id="collapseOrderOrderNumberPrefix" class="collapse" aria-labelledby="headingOrderOrderNumberPrefix" data-parent="#orderAccordion">
                <div class="card-body">
                  <SettingOrderOrderNumberPrefix :order-order-number-prefix="this.orderOrderNumberPrefix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOrderOrderNumberSuffix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOrderOrderNumberSuffix" aria-expanded="false" aria-controls="collapseOrderOrderNumberSuffix">

                  <span class="text-capitalize order-setting-title">Order Order Number Suffix</span>

                </button>
              </div>
              <div id="collapseOrderOrderNumberSuffix" class="collapse" aria-labelledby="headingOrderOrderNumberSuffix" data-parent="#orderAccordion">
                <div class="card-body">
                  <SettingOrderOrderNumberSuffix :order-order-number-suffix="this.orderOrderNumberSuffix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOrderOnSiteMaximumDistance">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOrderOnSiteMaximumDistance" aria-expanded="false" aria-controls="collapseOrderOnSiteMaximumDistance">

                  <span class="text-capitalize order-setting-title">Order On Site Maximum Distance</span>

                </button>
              </div>
              <div id="collapseOrderOnSiteMaximumDistance" class="collapse" aria-labelledby="headingOrderOnSiteMaximumDistance" data-parent="#orderAccordion">
                <div class="card-body">
                  <SettingOrderOnSiteMaximumDistance :order-on-site-maximum-distance="this.orderOnSiteMaximumDistance"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOrderNoteType">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOrderNoteType" aria-expanded="false" aria-controls="collapseOrderNoteType">

                  <span class="text-capitalize order-setting-title">Order Note Type</span>

                </button>
              </div>
              <div id="collapseOrderNoteType" class="collapse" aria-labelledby="headingOrderNoteType" data-parent="#orderAccordion">
                <div class="card-body">
                  <SettingOrderNoteType :order-note-type="this.orderNoteType"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOrderStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOrderStatus" aria-expanded="false" aria-controls="collapseOrderStatus">

                  <span class="text-capitalize order-setting-title">Order Status</span>

                </button>
              </div>
              <div id="collapseOrderStatus" class="collapse" aria-labelledby="headingOrderStatus" data-parent="#orderAccordion">
                <div class="card-body">
                  <SettingOrderStatus :order-status-setting="this.orderStatusSetting"/>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingOrderOrderNumberPrefix from "@/views/backEnd/settings/order/includes/SettingOrderOrderNumberPrefix";
import SettingOrderOrderNumberSuffix from "@/views/backEnd/settings/order/includes/SettingOrderOrderNumberSuffix";
import SettingOrderOnSiteMaximumDistance from "@/views/backEnd/settings/order/includes/SettingOrderOnSiteMaximumDistance";
import SettingOrderNoteType from "@/views/backEnd/settings/order/includes/SettingOrderNoteType";
import SettingOrderStatus from "@/views/backEnd/settings/order/includes/SettingOrderStatus";

export default {
  name: "SettingOrderList",
  components: {
    AppLayout,
    SettingOrderOrderNumberPrefix,
    SettingOrderOrderNumberSuffix,
    SettingOrderOnSiteMaximumDistance,
    SettingOrderNoteType,
    SettingOrderStatus,


  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["order"],

        key: [
          "order_order_number_prefix",
          "order_order_number_suffix",
          // "order_delivery_charge",
          // "order_registered_addresses",
          "order_on_site_maximum_distance",
          "order_note_type",
          "order_status",


        ],
      },
      currentState: {},
      frontEndErrors: [],

    }

  },

  computed: {
    ...mapGetters({
      orderOrderNumberPrefix: "appSettings/settingOrderOrderNumberPrefix",
      orderOrderNumberSuffix: "appSettings/settingOrderOrderNumberSuffix",
      orderOnSiteMaximumDistance: "appSettings/settingOrderOnSiteMaximumDistance",
      orderNoteType: "appSettings/settingOrderNoteType",
      orderStatusSetting: "appSettings/settingOrderStatus",


    }),

  },

  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",

    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.order-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.order-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
