<template>
  <div>
    <div class="px-2">

      <div class="row">
        <div class="table-responsive">
          <table id="users-list-datatable" class="table">
            <thead>
            <tr>
              <th class="position-relative" style="width: 5%">Value</th>
              <th class="position-relative" style="width: 20%">Name</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(orderStatus,index) in settingOrderStatus?.value ?? []" :key="index">
              <td>{{orderStatus.value}}</td>
              <td>{{orderStatus.name}}</td>

            </tr>
            </tbody>
          </table>
        </div>

        <div class="col-12">
          <div class="text-danger"><span>*For update order status, please contact with system Webmaster.</span></div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingOrderStatus",
  mixins: [ShowToastMessage, Loader],
  props: ['orderStatusSetting'],
  data() {
    return {
      currentSetting: this.orderStatusSetting,
      orderStatus : [],
    };
  },
  watch:{

  },
  computed: {
    ...mapGetters({
      settingOrderStatus: 'appSettings/settingOrderStatus',

    }),
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    },

  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

  },


}
</script>

<style scoped>

</style>